import React from "react";
import PropTypes from "prop-types";
import RestaurantSmallCard from "./RestaurantSmallCard";

export default function DirectoryListView({ locations }) {
  return (
    <div className="page-container">
      {locations.map((l, lIndex) => {
        return (
          <React.Fragment key={lIndex}>
            <RestaurantSmallCard
              name={l.node.name}
              style={l.node.cuisines[0].name}
              description={l.node.description}
              slug={l.node.slug}
              imageSrc={l.node.mainImage.childImageSharp.resize.src}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}

DirectoryListView.propTypes = {
  locations: PropTypes.array,
};

DirectoryListView.defaultProps = {
  locations: [],
};
