import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactMapboxGl, { Marker, Popup, ZoomControl } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Room as MarkerIcon } from "@material-ui/icons";
import RestaurantSmallCard from "./RestaurantSmallCard";

import "./scss/mapview.scss";

const Map = ReactMapboxGl({
  accessToken: process.env.MAPBOX_GL_TOKEN,
});

export default function MapView({ markers }) {
  const [popupDisplays, setPopupDisplays] = useState([]);

  const togglePopup = e => {
    let popupIndex = e.currentTarget.getAttribute("popupindex");
    let _popupDisplays = popupDisplays.map(() => false);
    _popupDisplays[popupIndex] = true;
    setPopupDisplays(_popupDisplays);
  };

  const resetMap = () => {
    let _popupDisplays = popupDisplays.map(() => false);
    setPopupDisplays(_popupDisplays);
  };

  const handleMapClick = () => {
    resetMap();
  };

  useEffect(() => {
    if (markers.length > 0) {
      resetMap();
    }
  }, [markers]);

  return (
    <Map
      style="mapbox://styles/mapbox/streets-v10"
      containerStyle={{
        height: "100vh",
        width: "100vw",
      }}
      onClick={handleMapClick}
      center={[-84.5143929, 39.105539]}
    >
      <ZoomControl />

      {markers.map((m, mIndex) => (
        <React.Fragment key={mIndex}>
          <Marker coordinates={[m.node.lon, m.node.lat]}>
            <MarkerIcon
              color="primary"
              popupindex={mIndex}
              onClick={togglePopup}
            />
          </Marker>
          {popupDisplays[mIndex] && (
            <Popup
              className="popup"
              anchor="top"
              coordinates={[m.node.lon, m.node.lat]}
              style={{ zIndex: 100 }}
            >
              <RestaurantSmallCard
                name={m.node.name}
                style={m.node.cuisines[0].name}
                description={m.node.description}
                slug={m.node.slug}
                imageSrc={m.node.mainImage.childImageSharp.resize.src}
              />
            </Popup>
          )}
        </React.Fragment>
      ))}
    </Map>
  );
}

MapView.propTypes = {
  markers: PropTypes.array,
};

MapView.defaultProps = {
  markers: [],
};
