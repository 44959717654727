import React, { useState } from "react";
import { Container, Button } from "@material-ui/core";
import { StaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import MapView from "../components/MapView";

import "./scss/directory.scss";
import DirectoryListView from "../components/DirectoryListView";

export default function Directory() {
  const [viewType, setViewType] = useState("map");

  const toggleView = () => {
    if (viewType === "map") {
      setViewType("list");
    } else {
      setViewType("map");
    }
  };

  return (
    <Layout>
      <StaticQuery
        query={graphql`
          query {
            allStrapiRestaurant {
              edges {
                node {
                  strapiId
                  name
                  slug
                  description
                  cuisines {
                    name
                  }
                  mainImage {
                    childImageSharp {
                      resize(cropFocus: CENTER, height: 400, width: 400) {
                        src
                      }
                    }
                  }
                  address
                  city
                  state
                  zip
                  lat
                  lon
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <>
              {viewType === "map" ? (
                <MapView markers={data.allStrapiRestaurant.edges} />
              ) : (
                <DirectoryListView locations={data.allStrapiRestaurant.edges} />
              )}
            </>
          );
        }}
      />

      <Container className="controls">
        <Button
          className="view-toggle-button"
          color="primary"
          variant="contained"
          onClick={toggleView}
        >
          Show {viewType === "map" ? "list" : "map"}
        </Button>
      </Container>
    </Layout>
  );
}
